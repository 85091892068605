import AWS from "aws-sdk";
// import { CognitoIdentityCredentials } from 'aws-sdk';
// import { config } from "aws-sdk/global"

const awsFileUpload = async (file, folderName, setUploadProgress) => {
  const S3_BUCKET = "knocknoccards";
  const REGION = "us-east-1";

  AWS.config.update({
    accessKeyId: "AKIATMHJNLYBVAJ5C5HU",
    secretAccessKey: "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",
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const FolderName = folderName;
  const fileName = file.name;
  const objectKey = `knocknock/${FolderName}/${fileName}`;

  const params = {
    Bucket: S3_BUCKET,
    Key: objectKey,
    Body: file,
  };

  var upload = s3
    .putObject(params)
    .on("httpUploadProgress", (evt) => {
      if (setUploadProgress)
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: (evt.loaded * 100) / evt.total,
        }));
    })
    .promise();

  try {
    await upload;
    const objectUrl = `https://${params.Bucket}.s3.${s3.config.region}.amazonaws.com/${objectKey}`;
    return { status: true, location: objectUrl };
  } catch (err) {
    console.error("Error", err);
    return { status: false, location: null };
  }
};

export default awsFileUpload;
